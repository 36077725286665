/**
 * Type: ページ
 * What: The FOODHALL
 */
import React from 'react';
// import { scroller } from 'react-scroll';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Image from '../../util/Image';
import BreadcrumbComponent from '../../components/Breadcrumb';
import SimpleSwiper from '../../components/Slider.fade';
import PostListComponent from '../../components/PostList/PostList.foodhall';
import useMediaQuery from '../../util/useMediaQuery';
import ShopList from '../../config/FoodHall';
import { Button } from '../../components/Btn';

const mq = useMediaQuery;

const pageTitle = 'The FOODHALL（フードホール）';
const pageDescription =
  'E・ZO FUKUOKAの3階、様々な革新的な「食」のエンタメを演出する、テラス席を備えた複数テナントによるフードホールフロア。「日本初上陸」「九州初上陸」「有名店による新ブランド」など“新しい＆美味しい”が詰まったフードホールです。';
const pageSlug = 'foodhall';
const pageLogo = `logo-${pageSlug}.png`;
// const pagebtnLogo = `btn-${pageSlug}.png`;

const sliderDataobj = [
  {
    filename: 'main-foodhall.jpg',
  },
];

export const List = (props) => (
  <div className="col-inner">
    <div className="col-image">
      {props.noLink ? (
        <div className="hv-link">
          {props.label ? (
            <span className="tag is-food">{props.label}</span>
          ) : null}
          {props.comingSoon ? (
            <figure className="image coming-soon">
              <Image filename={props.img ? props.img : 'now-printing.jpg'} />
              {props.hvImg ? (
                <Image src={props.hvImg} alt="" className="hv-image" />
              ) : null}
            </figure>
          ) : (
            <figure className="image grey-out">
              <Image filename={props.img ? props.img : 'now-printing.jpg'} />
              {props.hvImg ? (
                <Image src={props.hvImg} alt="" className="hv-image" />
              ) : null}
            </figure>
          )}
        </div>
      ) : (
        <Link to={props.link} className="hv-link">
          {props.label ? (
            <span className="tag is-food">{props.label}</span>
          ) : null}
          {props.greyOut ? (
            <figure className="image grey-out">
              <Image filename={props.img ? props.img : 'now-printing.jpg'} />
              {props.hvImg ? (
                <Image src={props.hvImg} alt="" className="hv-image" />
              ) : null}
            </figure>
          ) : (
            <figure className="image">
              <Image filename={props.img ? props.img : 'now-printing.jpg'} />
              {props.hvImg ? (
                <Image src={props.hvImg} alt="" className="hv-image" />
              ) : null}
            </figure>
          )}
        </Link>
      )}
    </div>
    <div className={`col-content ${props.bkClass ? props.bkClass : ''}`}>
      <h3 className="ttl">{props.name}</h3>
      <p className="text">{props.description}</p>
      {props.descriptionAttention ? (
        <p className="is-size-7 mt-1">
          {props.descriptionAttentionLink ? (
            <Link
              to={props.descriptionAttentionLink}
              className="description-link"
            >
              <span className="attention has-text-weight-bold">
                {props.descriptionAttention}
              </span>
            </Link>
          ) : (
            <span className="attention has-text-weight-bold">
              {props.descriptionAttention}
            </span>
          )}
        </p>
      ) : null}
      {props.takeout || props.uber ? (
        <div className="is-food-label">
          {props.takeout ? (
            <a
              href={props.takeoutUrl}
              className={`tag is-success${!props.takeoutUrl ? ' no-link' : ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              テイクアウト
              <br />
              対応店舗
            </a>
          ) : null}
          {props.uber ? (
            <a
              href={props.uberUrl}
              className={`tag is-link${!props.uberUrl ? ' no-link' : ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              デリバリー
              <br />
              対応店舗
            </a>
          ) : null}
        </div>
      ) : null}
    </div>
  </div>
);

const ContentsList = (props) => (
  <>
    <div>
      <List {...props} />
    </div>
  </>
);

// Data Props Template
const Template = () => (
  // const scrollToTarget = (e, elem, offset) => {
  //   e.preventDefault();
  //   const $offset = offset || -110;
  //   scroller.scrollTo(elem, {
  //     duration: 1200,
  //     delay: 0,
  //     offset: $offset,
  //     smooth: 'easeOutQuint',
  //   });
  // };
  <Layout pageSlug={pageSlug} bg="bg-2">
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="pageMain bs-2">
      <div className="kv-wrap bg-2">
        <div className="kv-inner">
          <SimpleSwiper data={sliderDataobj} />
        </div>
        <div className="container">
          <div className="inner">
            <div className="pageMain-header">
              <div className="floorNo c-2">
                <span>3</span>
                <span>F</span>
              </div>
              <h2 className="ttl">
                <Image src={pageLogo} />
                {mq('pc') && <span>The FOODHALL</span>}
              </h2>
            </div>
            <div className="overview">
              <p className="text">
                様々な革新的な「食」のエンタメを演出する、テラス席を備えた複数テナントによるフードホールフロア。
                <br />
                「日本初上陸」「九州初上陸」「有名店による新ブランド」など“新しい＆美味しい”が詰まったフードホール。
              </p>
            </div>
            {/* <div className="youtube-wrap cn-hide">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/2iHAVjMybQQ"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div> */}
          </div>
        </div>
      </div>
    </section>
    <section className="section sc-floorguide bg-13" id="information">
      <div className="container">
        <div className="sc-columns">
          {ShopList.map((shops) => (
            <div
              className="col col-4 floorbc2"
              key={shops.name}
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <ContentsList {...shops} />
            </div>
          ))}
        </div>

        {/*
        <div className="content-box" id="information">

          <h2 className="title is-3 en2">Information</h2>

          <table className="table is-narrow is-fullwidth">
            <tbody>
              <tr>
                <th>フロア全体の営業時間</th>
                <td className="tal">
                  (基本営業時間)11:00～23:00
                  {' '}
                  <br />
                  ※ みずほPayPayドーム開催イベントにより営業時間の変更あり
                </td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td className="tal">
                  000-000-0000
                </td>
              </tr>
              <tr>
                <th>予約</th>
                <td className="tal">可</td>
              </tr>
              <tr>
                <th>座席数</th>
                <td className="tal">ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。</td>
              </tr>
              <tr>
                <th>決済情報</th>
                <td className="tal">ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。</td>
              </tr>
              <tr>
                <th>その他</th>
                <td className="tal">ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。</td>
              </tr>
              <tr>
                <th>店舗公式サイト</th>
                <td className="tal">
                  <p className="underline-link">
                    <a href="http://www.ebisoba.com/" className="" target="_blank" rel="noopener noreferrer">
                      http://www.ebisoba.com/
                    </a>
                  </p>
                </td>
              </tr>

            </tbody>
          </table>

            <div className="content">
              <h3 className="subtitle is-5">注意事項</h3>
              <p className="list-mark2">
                ※ ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
                ダミーテキストです。これは仮のテキストになりますので後ほど差し替えになります。
              </p>
            </div>

      </div>
        */}
        <p className="list-mark mt-5">
          <span>※</span>
          「新たな業態」・「新たなブランド」の第1号店として開店し、全国初出店となります。
        </p>
      </div>
    </section>
    <PostListComponent />
  </Layout>
);

export default Template;
